<!--
 * @Description: 质检记录弹框
 * @Author: ChenXueLin
 * @Date: 2023-03-09 16:29:31
 * @LastEditTime: 2023-03-10 16:29:47
 * @LastEditors: ChenXueLin
-->
<template>
  <div :class="[recordVisible ? 'dialogMask' : 'hiddenMask']">
    <el-dialog
      title="质检记录"
      :visible.sync="recordVisible"
      :close-on-click-modal="false"
      width="850px"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="audit-dialog"
      :before-close="closeDialog"
      @open="openDialog"
      :modal-append-to-body="false"
    >
      <el-form
        ref="formData"
        :model="formData"
        v-if="type == 'maintenQualityInspection'"
      >
        <el-table
          :data="formData.tableData"
          border
          highlight-current-row
          class="inspectionRecordTable"
        >
          <el-table-column
            show-overflow-tooltip
            align="center"
            header-align="center"
            label="主体"
            prop="mainBodyId"
            width="230"
          >
            <template v-slot="scope">
              <el-form-item
                :prop="`tableData.${scope.$index}.mainBodyId`"
                :rules="[{ required: true, message: '请选择主体' }]"
              >
                <e6-vr-select
                  v-model="scope.row.mainBodyId"
                  :data="mainBodyIdList"
                  placeholder="主体"
                  title="主体"
                  clearable
                  virtual
                  :props="{
                    id: 'faultClassId',
                    label: 'faultName'
                  }"
                  @change="
                    (val, node) => {
                      changeMainbodyId(val, node, scope.$index);
                    }
                  "
                  @clear="
                    () => {
                      clearMainbodyId(scope.$index);
                    }
                  "
                  :is-title="true"
                ></e6-vr-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            header-align="center"
            label="故障点"
            prop="failPointId"
            width="230"
          >
            <template v-slot="scope">
              <el-form-item
                :prop="`tableData.${scope.$index}.failPointId`"
                :rules="[{ required: true, message: '请选择故障点' }]"
              >
                <e6-vr-select
                  v-model="scope.row.failPointId"
                  :data="scope.row.failPointList"
                  placeholder="故障点"
                  title="故障点"
                  clearable
                  virtual
                  :props="{
                    id: 'faultClassId',
                    label: 'faultName'
                  }"
                  :is-title="true"
                ></e6-vr-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            header-align="center"
            prop="responsibilityCode"
            label="故障责任归属"
            width="230"
          >
            <template v-slot="scope">
              <el-form-item
                :prop="`tableData.${scope.$index}.responsibilityCode`"
                :rules="[{ required: true, message: '请选择故障责任归属' }]"
              >
                <e6-vr-select
                  v-model="scope.row.responsibilityCode"
                  :data="responsibilityCodeList"
                  placeholder="故障责任归属"
                  title="故障责任归属"
                  clearable
                  virtual
                  :props="{
                    id: 'codeId',
                    label: 'codeName'
                  }"
                  :is-title="true"
                ></e6-vr-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            show-overflow-tooltip
            align="center"
            header-align="center"
            min-width="90"
          >
            <template slot-scope="{ row, $index }">
              <i
                class="e6-icon-add_line1"
                title="新增"
                @click="handleAdd(row, $index)"
              ></i>
              <i
                class="e6-icon-clear_fill"
                title="删除"
                @click="deleteColumn($index, row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <el-form label-width="100px" :model="submitForm" ref="submitForm">
        <el-form-item label="是否工艺纠正" prop="isProcessCorrection">
          <el-switch
            v-model="submitForm.isProcessCorrection"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="纠正内容" prop="processCorrection">
          <el-input
            placeholder="请输入纠正内容"
            v-model="submitForm.processCorrection"
            maxlength="200"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="异常纠正类型" prop="correctiveActionCode">
          <e6-vr-select
            v-model="submitForm.correctiveActionCode"
            :data="correctiveActionCodeList"
            placeholder="异常纠正类型"
            title="异常纠正类型"
            clearable
            :props="{
              id: 'codeId',
              label: 'codeName'
            }"
            virtual
            multiple
            :is-title="true"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="上传质检凭证" prop="fileIdList">
          <uploadOBS
            class="avatar-uploader"
            v-model="submitForm.fileIdList"
            :fileType="2"
            :limit="5"
            showFileList
            multiple
            showBtnType="0"
            :uploadFileList.sync="submitForm.fileList"
            isShowIcon
            :limitConfig="['jpeg', 'png', 'bmp', 'gif', 'jpg']"
          >
          </uploadOBS>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import uploadOBS from "@/components/uploadOBS/uploadOBS";
import { printError } from "@/utils/util";
import {
  findDownList,
  getFaultClassByParent,
  saveInspectionRecord,
  queryInspectionRecord
} from "@/api";
export default {
  name: "inspectionRecordDialog",
  components: { uploadOBS },
  data() {
    return {
      responsibilityCodeList: [],
      correctiveActionCodeList: [],
      mainBodyIdList: [],
      dialogLoading: false,
      formData: {
        tableData: [
          {
            mainBodyId: "",
            failPointId: "",
            responsibilityCode: "",
            failPointList: []
          }
        ]
      },
      submitForm: {
        isProcessCorrection: 0,
        inspectionRecordId: "",
        processCorrection: "",
        correctiveActionCode: [],
        fileIdList: [],
        fileList: []
      }
    };
  },
  mixins: [base],
  props: {
    recordVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    taskInspectionId: {
      type: [Number, String],
      default: ""
    }
  },

  computed: {},
  watch: {},
  methods: {
    openDialog() {
      this.$nextTick(() => {
        if (this.type == "maintenQualityInspection") {
          this.$refs.formData.clearValidate();
        }
      });

      this.initData();
    },
    // 初始化数据(获取表头,表格数据)
    async initData() {
      this.dialogLoading = true;
      try {
        let promiseList = [
          findDownList(["INSPECTION_RESPONSIBILITY", "CORRECTIVE_ACTION"]),
          getFaultClassByParent({
            parentId: 0,
            parentFaultType: 0,
            faultType: 1
          }),
          queryInspectionRecord({
            taskInspectionId: this.taskInspectionId
          })
        ];
        let [enumRes, faultRes, recordDetailRes] = await Promise.all(
          promiseList
        );
        //故障责任
        this.responsibilityCodeList = this.getFreezeResponse(enumRes, {
          path: "data.INSPECTION_RESPONSIBILITY"
        });
        //纠正类型
        this.correctiveActionCodeList = this.getFreezeResponse(enumRes, {
          path: "data.CORRECTIVE_ACTION"
        });
        this.mainBodyIdList = this.getFreezeResponse(faultRes, {
          path: "data"
        });
        let recordInfoList = recordDetailRes.data.recordInfoList;
        if (recordInfoList.length) {
          recordInfoList.map((item, index) => {
            item.failPointList = [];
            item.responsibilityCode = item.responsibilityCode
              ? item.responsibilityCode * 1
              : "";
            this.getFaultClassByParentReq(item.mainBodyId, index);
          });
          // this.formData.tableData = recordInfoList;
          this.$set(this.formData, "tableData", recordInfoList);
        } else {
          this.formData = {
            tableData: [
              {
                mainBodyId: "",
                failPointId: "",
                responsibilityCode: "",
                failPointList: []
              }
            ]
          };
        }

        let {
          isProcessCorrection,
          processCorrection,
          correctiveActionCode,
          fileUrlList,
          inspectionRecordId
        } = recordDetailRes.data;
        this.submitForm = {
          isProcessCorrection: isProcessCorrection ? isProcessCorrection : "",
          processCorrection: processCorrection ? processCorrection : "",
          correctiveActionCode: correctiveActionCode
            ? correctiveActionCode
            : [],
          inspectionRecordId,
          fileList: fileUrlList ? fileUrlList : [],
          fileIdList: fileUrlList ? fileUrlList.map(item => item.fileId) : []
        };
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },

    //修改主体
    changeMainbodyId(val, node, index) {
      this.getFaultClassByParentReq(node.faultClassId, index);
    },
    //清空主体
    clearMainbodyId(index) {
      let tableData = this.formData.tableData;
      tableData[index].failPointList = [];
      tableData[index].failPointId = "";
      this.formData.tableData = tableData;
    },
    //获取故障点
    async getFaultClassByParentReq(parentId, index) {
      try {
        let res = await getFaultClassByParent({
          parentId,
          parentFaultType: 1,
          faultType: 3
        });
        if (res.code == "OK") {
          let tableData = this.formData.tableData;
          tableData[index].failPointList = res.data;
          this.formData.tableData = tableData;
        }
      } catch (error) {
        printError(error);
      }
    },
    //点击添加
    handleAdd() {
      let tableData = this.formData.tableData;
      tableData.push({
        mainBodyId: "",
        failPointId: "",
        responsibilityCode: "",
        failPointList: []
      });
      this.formData.tableData = tableData;
    },
    //删除单行
    deleteColumn(index) {
      let tableData = _.cloneDeep(this.formData.tableData);
      if (tableData.length == 1) {
        this.$message.warning("至少保留一条数据");
        return;
      }
      tableData.splice(index, 1);
      this.formData.tableData = tableData;
    },
    handleSubmit() {
      if (this.type == "maintenQualityInspection") {
        this.$refs.formData.validate(valid => {
          if (valid) {
            this.dialogLoading = true;
            this.saveReq();
          }
        });
      } else {
        this.dialogLoading = true;
        this.saveReq();
      }
    },
    //保存记录
    async saveReq() {
      let recordInfoList = _.cloneDeep(this.formData.tableData);
      recordInfoList.map(item => {
        delete item.failPointList;
      });
      try {
        let res = await saveInspectionRecord({
          recordInfoList:
            this.type == "maintenQualityInspection" ? recordInfoList : [],
          taskInspectionId: this.taskInspectionId,
          ...this.submitForm
        });
        if (res.code == "OK") {
          this.$message.success("保存成功");
          this.$emit("confirm");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //关闭弹框
    closeDialog() {
      this.dialogLoading = false;
      this.$emit("update:recordVisible", false);
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.audit-dialog {
  .el-dialog__body {
    padding: 15px 20px;
    box-sizing: border-box;
    .inspectionRecordTable {
      .e6-vr-select {
        width: 200px;
      }
      [class^="e6-icon"] {
        height: 28px;
        color: #46bfea;
        line-height: 28px;
        font-style: normal;
        text-align: center;
        padding: 0 10px;
        cursor: pointer;
        border-radius: 3px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        padding: 0;
        font-size: 20px;
      }
    }
    // .el-input {
    //   width: 300px;
    // }
  }
}
</style>
