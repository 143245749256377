var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.recordVisible ? 'dialogMask' : 'hiddenMask']},[_c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.dialogLoading),expression:"dialogLoading"}],attrs:{"title":"质检记录","visible":_vm.recordVisible,"close-on-click-modal":false,"width":"850px","element-loading-background":_vm.loadingBackground,"custom-class":"audit-dialog","before-close":_vm.closeDialog,"modal-append-to-body":false},on:{"update:visible":function($event){_vm.recordVisible=$event},"open":_vm.openDialog}},[(_vm.type == 'maintenQualityInspection')?_c('el-form',{ref:"formData",attrs:{"model":_vm.formData}},[_c('el-table',{staticClass:"inspectionRecordTable",attrs:{"data":_vm.formData.tableData,"border":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"show-overflow-tooltip":"","align":"center","header-align":"center","label":"主体","prop":"mainBodyId","width":"230"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("tableData." + (scope.$index) + ".mainBodyId"),"rules":[{ required: true, message: '请选择主体' }]}},[_c('e6-vr-select',{attrs:{"data":_vm.mainBodyIdList,"placeholder":"主体","title":"主体","clearable":"","virtual":"","props":{
                  id: 'faultClassId',
                  label: 'faultName'
                },"is-title":true},on:{"change":function (val, node) {
                    _vm.changeMainbodyId(val, node, scope.$index);
                  },"clear":function () {
                    _vm.clearMainbodyId(scope.$index);
                  }},model:{value:(scope.row.mainBodyId),callback:function ($$v) {_vm.$set(scope.row, "mainBodyId", $$v)},expression:"scope.row.mainBodyId"}})],1)]}}],null,false,841507429)}),_vm._v(" "),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","align":"center","header-align":"center","label":"故障点","prop":"failPointId","width":"230"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("tableData." + (scope.$index) + ".failPointId"),"rules":[{ required: true, message: '请选择故障点' }]}},[_c('e6-vr-select',{attrs:{"data":scope.row.failPointList,"placeholder":"故障点","title":"故障点","clearable":"","virtual":"","props":{
                  id: 'faultClassId',
                  label: 'faultName'
                },"is-title":true},model:{value:(scope.row.failPointId),callback:function ($$v) {_vm.$set(scope.row, "failPointId", $$v)},expression:"scope.row.failPointId"}})],1)]}}],null,false,1639292804)}),_vm._v(" "),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","align":"center","header-align":"center","prop":"responsibilityCode","label":"故障责任归属","width":"230"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("tableData." + (scope.$index) + ".responsibilityCode"),"rules":[{ required: true, message: '请选择故障责任归属' }]}},[_c('e6-vr-select',{attrs:{"data":_vm.responsibilityCodeList,"placeholder":"故障责任归属","title":"故障责任归属","clearable":"","virtual":"","props":{
                  id: 'codeId',
                  label: 'codeName'
                },"is-title":true},model:{value:(scope.row.responsibilityCode),callback:function ($$v) {_vm.$set(scope.row, "responsibilityCode", $$v)},expression:"scope.row.responsibilityCode"}})],1)]}}],null,false,2218619240)}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"操作","show-overflow-tooltip":"","align":"center","header-align":"center","min-width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
                var $index = ref.$index;
return [_c('i',{staticClass:"e6-icon-add_line1",attrs:{"title":"新增"},on:{"click":function($event){return _vm.handleAdd(row, $index)}}}),_vm._v(" "),_c('i',{staticClass:"e6-icon-clear_fill",attrs:{"title":"删除"},on:{"click":function($event){return _vm.deleteColumn($index, row)}}})]}}],null,false,3049840166)})],1)],1):_vm._e(),_vm._v(" "),_c('el-form',{ref:"submitForm",attrs:{"label-width":"100px","model":_vm.submitForm}},[_c('el-form-item',{attrs:{"label":"是否工艺纠正","prop":"isProcessCorrection"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},model:{value:(_vm.submitForm.isProcessCorrection),callback:function ($$v) {_vm.$set(_vm.submitForm, "isProcessCorrection", $$v)},expression:"submitForm.isProcessCorrection"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"纠正内容","prop":"processCorrection"}},[_c('el-input',{attrs:{"placeholder":"请输入纠正内容","maxlength":"200"},model:{value:(_vm.submitForm.processCorrection),callback:function ($$v) {_vm.$set(_vm.submitForm, "processCorrection", $$v)},expression:"submitForm.processCorrection"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"异常纠正类型","prop":"correctiveActionCode"}},[_c('e6-vr-select',{attrs:{"data":_vm.correctiveActionCodeList,"placeholder":"异常纠正类型","title":"异常纠正类型","clearable":"","props":{
            id: 'codeId',
            label: 'codeName'
          },"virtual":"","multiple":"","is-title":true},model:{value:(_vm.submitForm.correctiveActionCode),callback:function ($$v) {_vm.$set(_vm.submitForm, "correctiveActionCode", $$v)},expression:"submitForm.correctiveActionCode"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"上传质检凭证","prop":"fileIdList"}},[_c('uploadOBS',{staticClass:"avatar-uploader",attrs:{"fileType":2,"limit":5,"showFileList":"","multiple":"","showBtnType":"0","uploadFileList":_vm.submitForm.fileList,"isShowIcon":"","limitConfig":['jpeg', 'png', 'bmp', 'gif', 'jpg']},on:{"update:uploadFileList":function($event){return _vm.$set(_vm.submitForm, "fileList", $event)},"update:upload-file-list":function($event){return _vm.$set(_vm.submitForm, "fileList", $event)}},model:{value:(_vm.submitForm.fileIdList),callback:function ($$v) {_vm.$set(_vm.submitForm, "fileIdList", $$v)},expression:"submitForm.fileIdList"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.closeDialog}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("确定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }